import React from "react";

// navigation
import { useNavigate } from "react-router-dom";

// state
import { authUser } from "../../../service/api/auth";
import { useDispatch } from "react-redux";

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);
  const [data, dataSet] = React.useState({
    email: "",
    password: "",
  });
  const [error, errorSet] = React.useState({
    email: "",
    password: "",
  });

  const validate = () => {
    let error = true;
    if (!data.email) {
      error = false;
      errorSet((prev) => ({ ...prev, email: "warning" }));
    }
    if (!data.password) {
      error = false;
      errorSet((prev) => ({ ...prev, password: "warning" }));
    }
    return error;
  };

  const onSubmit = async () => {
    if (validate()) {
      const response = await authUser(data, dispatch);
      response && navigate("/dashboard");
    }
    return;
  };

  const onChange = (e) => {
    const { value, name } = e.target;
    errorSet((prev) => ({ ...prev, [name]: "" }));
    dataSet((prev) => ({ ...prev, [name]: value }));
  };

  const onLoad = React.useCallback(() => {
    localStorage.removeItem("@serial");
  }, []);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return { showPassword, error, setShowPassword, data, onChange, onSubmit };
};

export default useLogin;
