import React from 'react'
import useMultiSteps from './hooks/useMultiSteps';

const MultiStep = ({children}) => {
  const {step, _nextStep, _prevStep, saveState, getState} = useMultiSteps();
  return (
    React.Children.map(children, (el, index) => {
        if (index === step) {
          return React.cloneElement(el, {
            currentIndex: step,
            isLast: step === children.length - 1,
            nextStep: () => _nextStep(children.length),
            prevStep: _prevStep,
            saveState, 
            getState,
          })
        }
        return null;
    })
  )
}

export default MultiStep
