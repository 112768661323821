import React from 'react'
import Button from '../../../components/elements/form/Button'
// import Card from '../../../components/ui/Cards/Card'
// import { Link } from 'react-router-dom'
// import TextInput from '../../../components/elements/form/TextInput'
// import { faKey, faUserLarge } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import CheckBoxInput from '../../../components/ui/Cards/CheckBoxInput'
// import Button from '../../../components/elements/form/Button'

const CompanyData = (props) => {
  console.log(props)
  return (
    // <Card>
    <>
      <h4>Company Data</h4>
      <Button title='Next' onHandler={() => props.nextStep()} />
    </>
    // </Card>
  )
}

export default CompanyData
