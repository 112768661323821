import Lottie from 'react-lottie';

import loader from '../../../assets/lottie/button_loader.json'

const Button = ({title, loading, onHandler}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <button disabled={loading} className='bg-[green] w-[100%] border-none rounded-md text-[white] text-[16px] font-bold py-4' onClick={onHandler}>

      <div className='flex justify-center'>
        <span className='flex'>
          {loading && <Lottie 
          options={defaultOptions}
          className="flex mr-2"
          height={25}
          width={25}/>}
          {title}
        </span>
      </div>
    </button>
  )
}

export default Button
