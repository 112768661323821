import './homeTestimonialCard.css'

const HomeTestimonialCard = ({ title, content, img, btn, color, btnColor }) => {
  return (
    <div className='card' style={{ background: color }}>
      <div className='card-content'>
        <div className='card-content-btn' style={{ background: btnColor }}>
          {btn}
        </div>
        <div className='card-content-main'>
          <h2>{title}</h2>
          <p>{content}</p>
        </div>
      </div>
      <img src={img} alt='img' />
    </div>
  )
}

export default HomeTestimonialCard
