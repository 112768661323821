import React from "react";

// constants and icon
import { AiFillCaretDown } from "react-icons/ai";
import avatar from "../../../assets/images/png/user.jpeg";

// state
import { getProfile } from "../../../service/api/auth";
import { useDispatch, useSelector } from "react-redux";

const NavigationBar = () => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth);

  const onLoad = React.useCallback(() => {
    getProfile(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  console.log(user);

  return (
    <nav className="w-full flex justify-between shadow bg-[green] px-5 py-4">
      <img
        src="https://develop.corporate-setup.com/web-assets/img/logo/logo-2.png"
        alt="logo"
      />
      <div className="flex items-center">
        <div className="p-[2px] border-2 border-[grey] rounded-full mr-2">
          <img
            src={avatar}
            alt="User Avatar"
            className="h-11 w-11 rounded-full"
          />
        </div>
        <div className="cursor-pointer">
          <AiFillCaretDown className="" size={30} />
        </div>
      </div>
    </nav>
  );
};

export default NavigationBar;
