import axiosInstance from "../../utils/axiosInstance";

// notification
import { notify } from "../../components/alerts";

// loading
import { setUser } from "../../store/slices/auth";
import { setLoading, falseLoading } from "../../store/slices/auth";

export const authUser = async (formData, dispatch) => {
  dispatch(setLoading());
  let result;
  try {
    const response = await axiosInstance.post("/auth/login", formData);

    const { access_token, user } = response?.data;
    localStorage.setItem("@serial", access_token);
    dispatch(setUser(user));
    result = true;
  } catch (err) {
    const { message } = err?.response?.data;
    notify({ type: "error", message });
    result = false;
  }
  dispatch(falseLoading());
  return result;
};

export const getProfile = async (dispatch) => {
  try {
    const response = await axiosInstance.get("/get-profile");

    dispatch(setUser(response.data));
  } catch (err) {}
};
