import axios from "axios";

// app
import app from "../config/app";

let headers = {};
const axiosInstance = axios.create({
  baseURL: app.baseURL,
  headers,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("@serial");
    if (token) {
      config.headers.Authorization = "bearer " + token;
    }
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";

    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) =>
    new Promise((resolve, reject) => {
      resolve(response);
    }),
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    if (
      error.response.status === 401 &&
      window.location.pathname !== "/login"
    ) {
      window.location.href = "/login";
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);
export default axiosInstance;
