import './homeHero.css'

const HomeHero = () => {
  return (
    <div className='homeHeroContainer'>
      <span className='homeHeroMask' />
      <div style={{ position: 'relative' }}>
        <div className='homeHeroTxt'>
          <h1 className='homeHeroTxtH1'>CORPORATE SET-UP</h1>
          <p className='homeHeroTxtP'>
            We set up organisation and companies from incorporation, Website
            development, Register it with regulatory bodies, Train Staffs to
            achieve its corporate objectives.{' '}
          </p>
          <button className='homeHeroTxtButton'>Contact us</button>
        </div>
      </div>
    </div>
  )
}

export default HomeHero
