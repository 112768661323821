import './card.css'

const Card = ({children}) => {
  return (
    <div className='cardContainer'>
      <div className='cardWrapper'>
        {children}
      </div>
    </div>
  )
}

export default Card
