import { store } from "./store";
import { Provider } from "react-redux";
import { MainRoute } from "./route";

// styles
import "./index.css";

function App() {
  return (
    <Provider store={store}>
      <MainRoute />
    </Provider>
  );
}

export default App;
