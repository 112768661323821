import React from "react";

const CheckBox = ({ name, value, title }) => {
  return (
    <div>
      <input
        type="checkbox"
        id={name}
        name={name}
        value={value}
        className="mr-2"
      ></input>
      <label htmlFor={name} className="text-gray-400 font-400 text-md">
        {title}
      </label>
    </div>
  );
};

export default CheckBox;
