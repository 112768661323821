import HomeCard from '../homeCard/HomeCard'
import './homeInfo.css'
import frame1 from '../../assets/frame1.jpeg'
import frame2 from '../../assets/frame2.jpeg'
import frame3 from '../../assets/frame3.jpeg'

const HomeInfo = () => {
  return (
    <div className='homeInfoContainer'>
      <div className='homeInfoContainerWrapper'>
        <HomeCard
          title='Incorporation'
          content='We incorporate companies and organization on behalf of the company and put it on the platform for our customers to download...'
          img={frame1}
        />
        <HomeCard
          title='Register With Regulatory Bodies'
          content='We register your company with the required regulatory bodies that will enables it function in the sector, also advice CEO on how to manage cost for new incorporated Companies'
          img={frame2}
        />
        <HomeCard
          title='Hire a professional Bidder'
          content='We bid for oil and gas job on behalf of our customer on e-market portals and also physically represent them on bid that required physical presence.'
          img={frame3}
        />
      </div>
    </div>
  )
}

export default HomeInfo
