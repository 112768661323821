import './homeTestimonies.css'
import illustration1 from '../../assets/illustration-5.svg'
import illustration2 from '../../assets/illustration-6.svg'
import illustration3 from '../../assets/illustration-7.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import HomeTestimonialCard from '../homeTestimonialCard/HomeTestimonialCard'

const HomeTestimonies = () => {
  return (
    <div className='homeTestimonies'>
      <div className='homeTestimoniesWrapper'>
        <div className='testimoniesT'>
          <h1 className='testimoniesTH1'>Testimonies from our past training</h1>
          <h2 className='testimoniesTH2'>
            Get in touch with us for inhouse training
          </h2>
          <div className='testimoniesBtn'>
            <FontAwesomeIcon
              icon={faPlayCircle}
              className='testimoniesBtnIcon'
            />
            <p className='testimoniesBtnP'>Play video</p>
          </div>
        </div>
      </div>
      <div className='testimoniesContentWrapper'>
        <div className='testimoniesContent maxWrapper'>
          <HomeTestimonialCard
            img={illustration1}
            btn='class'
            title='this is for e-flyer'
            content='All the class e-flyers will be posted here for trainings .'
            color='#FFF7E8'
            btnColor='#FFBE3D'
          />
          <HomeTestimonialCard
            img={illustration2}
            btn='class'
            title='Rules not to follow'
            content='All the class e-flyers will be posted here for trainings .'
            color='rgb(235,249,244)'
            btnColor='#5CC9A7'
          />
          <HomeTestimonialCard
            img={illustration3}
            btn='class'
            title='oil and gas'
            content='All the class e-flyers will be posted here for trainings .'
            color='#FDEBED'
            btnColor='#F25767'
          />
        </div>
      </div>
    </div>
  )
}

export default HomeTestimonies
