import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TextInput = ({ label, container, toggler, icon, error, handleToggler, ...rest }) => {
  return (
    <div className={`w-100 mb-10 ${container}`}>
      <div className='w-100 flex justify-between'>
        <div className=''>
          <label className='text-md font-[400] text-[grey]' htmlFor={label}>{label}</label>
        </div>
        <div>
          {toggler && (
            <span className='cursor-pointer underline decoration-[grey] decoration-dotted border-w-2 broder-[grey]' onClick={handleToggler}>
              {' '}
              {toggler}
            </span>
          )}
        </div>
      </div>

      <div className={`focus-within:border-[lightgreen] flex justify-center items-center ${error ? 'border-[red]' : 'border-[#a9aec4]'} border p-2 rounded-lg`}>
        <FontAwesomeIcon icon={icon} className='text-xl border-r border-[#a9aec4] p-2' />
        <input id={label} {...rest} className='w-[100%] bg-[#ffffff] border-none] outline-none text-xl px-4' />
      </div>
     
    </div>
  )
}

export default TextInput

