import { Link } from 'react-router-dom'
import './navbar.css'

const Navbar = () => {
  return (
    <div className='navbar border-b border-[gray] px-2'>
      <div className='navContainer'>
        <div className='leftNavbar'>
          <span className='logo'>
            <img
              src='https://develop.corporate-setup.com/web-assets/img/logo/logo-2.png'
              alt='logo'
            />
          </span>
        </div>
        <div className='centerNavbar'>
          <div className='links'>
            <ul>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/'>About Us</Link>
              </li>
              <li>
                <Link to='/'>Services</Link>
              </li>
              <li>
                <Link to='/'>Training</Link>
              </li>
              <li>
                <Link to='/'>Blog</Link>
              </li>
              <li>
                <Link to='/'>Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className='rightNavbar'>
          <div className='navItems'>
            <Link to='/auth/login'>
              <button className='navButton'>Sign in</button>
            </Link>
            <Link to='/auth/register'>
              <button className='navButton'>Register</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
