import React from "react";

// routes
import { Routes, Route } from "react-router-dom";

// theme
import AppTheme from "../../themes/app";

// pages
import { Dashboard } from "../../pages/dashboard";

const App = () => {
  return (
    <AppTheme>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </AppTheme>
  );
};

export default App;
