import React from "react";

// route
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import PrivateRoute from "./routers/PrivateRoute";

// pages
import { Landing } from "../pages/auth";
import App from "./routers/App";
import { Register, Login } from "../pages/auth";

const NonAuth = ({ children }) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("@serial");
  React.useEffect(() => {
    if (token) navigate("/dashboard");
  }, [navigate, token]);
  return children;
};

export const MainRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/login"
          element={
            <NonAuth>
              <Login />
            </NonAuth>
          }
        />
        <Route
          path="/register"
          element={
            <NonAuth>
              <Register />
            </NonAuth>
          }
        />
        <Route
          exact
          path="/"
          element={
            <NonAuth>
              <Landing />
            </NonAuth>
          }
        />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <App />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
