import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: localStorage.getItem("@token") || null,
  user: null,
  loading: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    falseLoading: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setLoading, falseLoading } = authSlice.actions;

export default authSlice.reducer;
