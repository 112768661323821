import React from 'react'
import './register.css'
import { MultiStep } from '../../../modules'
import { Guest } from '../../../themes'

// pages
import BioData from '../misc/BioData'
import CompanyData from '../misc/CompanyData'
import Card from '../../../components/ui/Cards/Card'

const Register = () => {
  return (
    <Guest>
      <div className='registerContainer'>
        <span className='mask' />
        <div style={{ position: 'relative' }}>
          <Card>
            <h4>Register</h4>
            <MultiStep>
              <CompanyData />
              <BioData />
            </MultiStep>
          </Card>
        </div>
      </div>
    </Guest>
  )
}

export default Register
