import './homecard.css'

const HomeCard = ({ img, title, content }) => {
  return (
    <div className='homeCardContainer'>
      <div className='homeCardImgContainer'>
        <img src={img} alt='img' className='homeCardImg' />
      </div>
      <h1 className='homeCardT'>{title}</h1>
      <p className='homeClassP'>{content}</p>
    </div>
  )
}

export default HomeCard
