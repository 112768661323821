import Footer from "../../../components/footer/Footer";
import GetStarted from "../../../components/getStarted/GetStarted";
import HomeHero from "../../../components/homeHero/HomeHero";
import HomeInfo from "../../../components/homeInfo/HomeInfo";
import HomeInfo2 from "../../../components/homeInfo2/HomeInfo2";
import HomeTestimonies from "../../../components/homeTestimonies/HomeTestimonies";
import Navbar from "../../../themes/guest/navbar/Navbar";

const Landing = () => {
  return (
    <>
      <Navbar />
      <HomeHero />
      <GetStarted />
      <HomeInfo />
      <HomeInfo2 />
      <HomeTestimonies />
      <Footer />
    </>
  );
};

export default Landing;
