import React from "react";

// components
import NavigationBar from "./navbar";

const AppTheme = ({ children }) => {
  return (
    <div className="">
      <NavigationBar />
      {children}
    </div>
  );
};

export default AppTheme;
