import './footer.css'

const Footer = () => {
  return (
    <div className='footerContainer'>
      <div className='footer1A'>
        <div className='footer1'>
          <div className='footer1Right'>
            <h3 className='descH'>We Set up Just Like You Like It</h3>
            <p className='descP'>
              Building the world with like mind of corporate society.
            </p>
          </div>

          <div className='footer1Left'>
            <button className='signIn1'>Login</button>
            <button className='signIn2'>Register</button>
          </div>
        </div>
        <div className='horizon1'></div>
      </div>
      <div className='footer2'>
        <div className='footer2L'>
          <img
            src='https://develop.corporate-setup.com/web-assets/img/logo/logo-2.png'
            alt='logo'
            className='footer2LLogo'
          />

          <h1 className='footerH'>
            23b, Bashorun Okusanya, Off Admiralty, Lekki Phase 1, Lagos Nigeria.
          </h1>
          <p className='footerP'>
            Corporate-Setup Limited: Set up organisation and companies from
            incroporated, Website development, Register it with regulatory
            bodies, Train Staffs to a standard.
          </p>
        </div>
        <div className='footer2R'>
          <div className='account'>
            <h4 className='contactH4'>Account</h4>
            <ul className='contactUl'>
              <li className='contactLi'>Home</li>
              <li className='contactLi'>Blog</li>
              <li className='contactLi'>Training</li>
            </ul>
          </div>
          <div className='account'>
            <h4 className='contactH4'>About</h4>
            <ul className='contactUl'>
              <li className='contactLi'>Services</li>
              <li className='contactLi'>About Us</li>
              <li className='contactLi'>Contact Us</li>
            </ul>
          </div>
          <div className='account'>
            <h4 className='contactH4'>Company</h4>
            <ul className='contactUl'>
              <li className='contactLi'>Opportunities</li>
              <li className='contactLi'>FAQ</li>
              <li className='contactLi'>Support</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='footer3B'>
        <div className='horizon2'></div>
        <div className='footer3'>
          <div className='footer3Left'>
            <h4 className='copy'>
              2022 &copy; <span>coporate setup.</span> All rights reserved
            </h4>
          </div>
          <div className='footer3Right'>
            <div className='paySecure'>
              <ul className='paySecureUl'>
                <li className='paySecureLi'>Terms</li>
                <li className='paySecureLi'>Privacy</li>
                <li className='paySecureLi'>Cookies</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
