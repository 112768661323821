import React from 'react'

const useMultiSteps = () => {
  const [step, stepSet] = React.useState(0);
  const [state, stateSet] = React.useState({});

  const saveState = (data) => {
    stateSet(prev => ({...prev, ...data}))
  }

  const getState = () => {
    return state;
  }

  const _nextStep = (size) => {
    if (step !== size - 1) {
      stepSet(step + 1);
    }
  };

  const _prevStep = () => {
    if (step !== 0) {
        stepSet(step - 1);
    }
  };
  return {state, stateSet, step, stepSet, _nextStep, _prevStep, saveState, getState}
}

export default useMultiSteps
