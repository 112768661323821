import React from "react";

// compoennts
import Navbar from "./navbar/Navbar";

const Guest = ({ children }) => {
  return (
    <div className="">
      <Navbar />
      {children}
    </div>
  );
};

export default Guest;
