import { faGear, faPalette, faShop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './homeInfo2.css'

const HomeInfo2 = () => {
  return (
    <div className='homeInfo2Container'>
      <div className='homeInfo2ContainerR'>
        <div className='homeInfo2ContainerRWrapper'>
          <img
            alt='ImagePlaceholder'
            src='https://develop.corporate-setup.com/web-assets/img/landing-images/d2.jpg'
            className='homeInfo2Img'
          />
        </div>
      </div>

      <div className='homeInfo2ContainerL'>
        <h1 className='homeInfo2H'>
          We deliver the high quality end results you need
        </h1>
        <p className='homeInfo2P'>WHY USE CORPORATE-SET UP LIMITED:-</p>
        <ul>
          <li className='homeInfo2Li'>
            <span className='homeInfo2Shape'>
              <FontAwesomeIcon icon={faShop} className='homeInfo2ShapeIcon' />
            </span>
            Up-to-Date Technology
          </li>
          <li className='homeInfo2Li'>
            <span className='homeInfo2Shape'>
              <FontAwesomeIcon
                icon={faPalette}
                className='homeInfo2ShapeIcon'
              />
            </span>
            Professionalism
          </li>
          <li className='homeInfo2Li'>
            <span className='homeInfo2Shape'>
              <FontAwesomeIcon icon={faGear} className='homeInfo2ShapeIcon' />
            </span>
            Tailor-made Training Etc.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default HomeInfo2
