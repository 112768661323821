import React from "react";
import useLogin from "../hooks/useLogin";

// component
import { Guest } from "../../../themes";
import TextInput from "../../../components/elements/form/TextInput";
import Button from "../../../components/elements/form/Button";
import CheckBox from "../../../components/elements/form/CheckBox";

// navigation
import { Link } from "react-router-dom";

// constants
import { faKey, faUserLarge } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// state
import { useSelector } from "react-redux";

const Login = () => {
  const loading = useSelector(({ auth }) => auth.loading);
  const { showPassword, error, setShowPassword, data, onChange, onSubmit } =
    useLogin();

  return (
    <Guest>
      <div className="flex flex-1 flex-col justify-center items-center py-10 bg-[green]">
        <div className="w-[60%] rounded-lg bg-white px-10 py-10 mb-9">
          <div className="text-center flex justify-center items-center my-3">
            <h4 className="text-2xl mr-2 font-bold">Login</h4>
            <FontAwesomeIcon icon={faUserLarge} className="text-2xl" />
          </div>

          <h3 className="text-center text-[gray] my-2">
            Sign In to your dashboard
          </h3>

          <TextInput
            name="email"
            icon={faUserLarge}
            value={data.email}
            error={error.email}
            label="E-Mail Address"
            placeholder="Enter email"
            onChange={onChange}
          />
          <TextInput
            icon={faKey}
            name="password"
            label="Password"
            error={error.password}
            value={data.password}
            placeholder="Enter password"
            onChange={onChange}
            handleToggler={() => setShowPassword(!showPassword)}
            toggler="show password"
            type={!showPassword ? "password" : "text"}
          />

          <CheckBox name="remeber_me" title="Remember Me" />

          <div className="flex justify-between items-center my-5">
            <div className="w-[50%]">
              <Button title="Login" onHandler={onSubmit} loading={loading} />
            </div>

            <div>
              <Link
                to="/forget-password"
                className="fPassword text-[blue] font-semibold"
              >
                Forget Your Password?
              </Link>
            </div>
          </div>
        </div>
        <div className="w-[100%] text-center">
          <span className="text-white font-bold">
            Not a member?
            <Link to="/register"> Click here to create account</Link>
          </span>
        </div>
      </div>
    </Guest>
  );
};

export default Login;
