import './getStarted.css'

import { Link } from 'react-router-dom'


const GetStarted = () => {
  return (
    <div className='getStartedContainer'>
      <div className='getStartedContainerL'>
        <h1 className='getStartedH'>
          We set up just like{' '}
          <span className='getStartedSpan'> You Like It</span>
        </h1>
        <p className='getStartedP'>
          Behold the power to set up your company from anywhere and monitor its
          progress from anywhere..
        </p>
        <Link to='/auth/register'>
          <button className='getStartedButton'>Get started</button>
        </Link>
      </div>
      <div className='getStartedContainerR'>
        <div className='getStartedContainerRWrapper'>
          <img
            alt='ImagePlaceholder'
            src='https://develop.corporate-setup.com/web-assets/img/landing-images/d1.jpg'
            className='getStartedImg'
          />
        </div>
      </div>
    </div>
  )
}

export default GetStarted
